/* Compact table styles specifically for deferred payments */

/* Reduce overall padding in table */
.compact-table td,
.compact-table th {
    padding: 0.25rem 0.5rem !important;
}

/* Make chevron icons smaller and tighter */
.compact-table button svg {
    width: 14px !important;
    height: 14px !important;
}

/* Reduce spacing between rows */
.compact-table tr {
    height: auto !important;
    line-height: 1.2 !important;
}

/* Make font smaller for detail rows */
.compact-table .detail-row td {
    font-size: 0.75rem !important;
    padding-top: 0.15rem !important;
    padding-bottom: 0.15rem !important;
}

/* Styling for the hierarchy levels */
.compact-table .customer-row {
    background-color: #f3f4f6;
    font-weight: 600 !important;
}

.compact-table .year-row {
    background-color: #f9fafb !important;
    font-weight: 500 !important;
}

.compact-table .commodity-row {
    background-color: #f9fafb !important;
    font-weight: 400 !important;
}

/* Make subtotal rows more compact */
.compact-table .subtotal-row td {
    padding-top: 0.15rem !important;
    padding-bottom: 0.15rem !important;
    /* border-top: 1px solid #e5e7eb !important; */
}

/* Add some space after each customer group */
.compact-table .customer-row:not(:first-child) {
    border-top: 2px solid #e5e7eb !important;
}

/* Adjust spacing inside cells with buttons */
.compact-table .button-cell {
    padding-right: 0.25rem !important;
}

/* Reduce table font size overall */
.compact-table {
    font-size: 0.875rem !important;
}

/* Adjust table header */
.compact-table thead th {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    background-color: #f9fafb !important;
}
